* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  

}

.btn-container {
  display: flex;
  justify-content: space-evenly;
  gap: 2px;
}

/* Base styles for the navigation bar */
.header {
  background-color: #f8f8f8;
  height: 80px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* To position the pseudo-element relative to the header */
}

.header::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%; /* Adjust this value to control the length of the line */
  height: 2px; /* Thickness of the line */
  background: #c9c8c8;/* Gradient effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Blurry effect */
}

.header-content-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 40px;
  width: auto;
  /* Adjust the height of the logo */
}

.primary-menu-menu {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
}

.primary-menu-menu li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 15px;
}

.primary-menu-menu li a:hover {
  color: #fe4a49;
  /* Adjust the color of the link on hover */
}

/* Styling for the hamburger icon */
.hamburger-icon {
  font-size: 24px;
  cursor: pointer;
}

/* Styling for the menu items */
.menu-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  /* Semi-transparent white background */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  /* Add a subtle shadow */
}

.menu-items.active {
  display: block;
  width: 80%;
  text-align: center;
}

.menu-items li {
  margin: 10px 0;
}

.menu-items a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}

@media screen and (min-width: 802px) {
  .nav-menu {
    display: none;
  }
}

/* Media queries for responsiveness */
@media screen and (max-width: 801px) {
  .logo img {
    height: 40px;
   
  }
  .nav-menu{
    z-index: 998;
  
  }
  .primary-menu{
    display: none;
  }
}
  


/* Styles for desktop */
.container-1 {
  background: url(../public/media/Main1BG.png);
  height: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4% 8%;
  background-repeat: no-repeat;

}

.text-1 {
  text-align: left;
  padding: 10% 0;
  width: 48%;
}

.text-1 h1 {
  font-size: 36px;
  text-align: left;
  font-family: Montserrat;
  margin: 10px 0;
}
.changing-span{
  font-family: Outfit;
}
.expert {
  font-weight: bold;
  color:#FE5369;
  opacity: 0;
  transition: opacity .5s ease-in-out; /* Add smooth transition */
}

.expert.visible {
  opacity: 1;
}

.expert.hidden {
  display: none;
}

/* Estilos del botón */
.styled-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #FE5369;
  transition: background-color 0.3s ease-in-out;
}

.styled-button:hover {
  background-color: #E1306C;
}

.styled-button2 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #FE5369;
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out;
}

.styled-button2:hover {
  background-color: #FE5369;
  color: #fff;
}


.container1-img{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.s-1-p {
  font-size: 16px;
  font-weight: bold;
}

.discount {
  color: rgb(255, 217, 0);
}

.fa-arrow-right {
  color: #E1306C;
  transition: all ease 300ms;
}

.fa-arrow-right:hover {
  scale: 1.3;

}

@media (max-width: 1024px) {
  .container-1 {
    flex-direction: column;
    height: auto;

  }

}

/* Styles for mobile */
@media (max-width: 768px) {
  .container-1 {
    flex-direction: column;
    height: auto;
  }

  .text-1 {
    width: 100%;
  }

  .text-1 h1 {
    text-align: left;
    font-weight: bold;
  }

  .fsd-pic {
    max-width: 100%;
    /* Ensure the image doesn't overflow its container */
    margin-top: 0px;
    padding: 4px;
    /* Add some spacing between text and image */
  }
}

/*MAIN1PART2*/
.main-part2 {
  padding: 60px 5%;
  background-color: #f9f9f9; /* Fondo ligero para destacar los videos */
  display: flex;
  justify-content: center;
}

.video-container {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.video-item {
  width: 30%; /* Tres videos en fila */
  position: relative;
  padding-top: 50.78%; /* Mantiene una relación de aspecto de 9:16 (100 / 9 * 16 = 177.78) */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.reel-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que el video ocupe todo el espacio disponible manteniendo su relación de aspecto */
  border-radius: 10px;
}
.video-caption {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-family: 'Outfit', sans-serif;
}

/* Diseño responsivo */
@media (max-width: 1024px) {
  .video-container {
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }

  .video-item {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .video-item {
    width: 90%;
  }

  .video-caption {
    font-size: 14px;
  }

}

/*Why Us*/




.w-u {
  margin-top: 0;
  height: 790px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

}

.section-title {
  font-weight: 600;
  color: #FE5369;
  text-align: center;
  font-size: 30px;
  padding-top: 30px;
}

.section-description {
  text-align: center;
  font-size: 18px;
  width: 950px;
}

.w-u-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 90px;


}

.w-u-card {
  width: 330px;
  height: 320px;
  text-align: left;
  padding: 20px;
  color: #000;
  background: #fff;
  font-weight: bold;
  transition: all ease 300ms;
  border: solid 1px #000;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
  border-radius: 50px;



}

.w-u-card:hover {
  scale: 1.1;
}





.w-u-card img {
  width: 105px;
}

.w-u-card-title {
  font-family: Montserrat;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.w-u-card-p {
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
}

.vmtitle {
  font-size: 23px;
  font-weight: bold;
  text-align: center;
}

.vm p {
  font-size: 18px;
  text-align: center;
  width: 950px;
}

@media (max-width: 1024px) {
  .w-u {
    height: auto;
    padding: 10px;
  }

  .section-title {
    margin-top: 0;
  }

  .section-description {
    text-align: center;
    font-size: 15px;
    width: 100%;
  }

  .w-u-cards {
    display: flex;
    gap: 20px;
  }

  .vmtitle {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
  }

  .vm p {
    font-size: 15px;
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 985px) {
  .w-u-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
  }
}


@media (max-width: 575px) {
  .w-u-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
  }
}

/*OUR SERVICES*/
.our-services {
  height: auto;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 66px;
  background: #F4F4F8;

}

.services-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 65px;
  /* Espacio entre las tarjetas */
  max-width: 100%;
  /* Asegura que las tarjetas se ajusten al ancho del contenedor */
  justify-content: center;
  align-items: center;
}

/* Estilo para cada tarjeta */
.service-card {
  display: flex;
  gap: 8px;
  padding: 10px;
  align-items: center;
  width: 100%;
  /* Las tarjetas ocuparán todo el espacio disponible en su columna */
  max-width: 250px;
  /* Establece un ancho máximo para las tarjetas */
  height: 150px;
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 25px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
  transition: all ease 300ms;
}

.service-card:hover {
  scale: 1.1;
  cursor: pointer;
}

.service-card img {
  width: 80px;
}

.service-card p {
  font-size: 14px;
  font-weight: bold;
}

.service-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}



@media (max-width: 1024px) {
  .our-services {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
    background: #F4F4F8;
    padding-bottom: 30px;
    padding: 10px;

  }

  .services-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    /* Espacio entre las tarjetas */
    max-width: 100%;
    /* Asegura que las tarjetas se ajusten al ancho del contenedor */
    justify-content: center;
    align-items: center;
  }

  .service-card {
    width: 100px;

  }

  .service-card p {
    display: none;
  }

  .service-card:hover p {
    display: block;
    /* You can adjust the styling of the label here */
    position: absolute;
    background: #333;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    /* You can adjust the label's position and styling as needed */
    top: -30px;
    left: 0;
    width: auto;
  }

  .service-bottom button {
    margin-bottom: 60px;
  }

}

.map-section {
  height: 675px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.map-container {
  width: 800px;
}

.map-container img {
  width: 100%;
}

@media (max-width: 1024px) {
  .map-section {
    height: auto;
    padding: 10px;
  }

  .map-container {
    width: 100%;
    margin-bottom: 60px;
  }
}

.rocket {
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
  cursor: pointer;
}

.rocket img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .rocket {
    width: 40px;
    height: 40px;
  }
}

/*-----------------------------------------------------------------------------------------------*/
/*MORE INFO SCREEN*/
.container-moreinfo {
  display: flex;
}

/* Estilos para las columnas */
.column {
  /* Configura el ancho de cada columna */
  flex: 1;
  /* Esto permite que las columnas se ajusten automáticamente */
  padding: 10px;
  /* Agrega un poco de espacio entre las columnas (opcional) */
}

/* Configura el ancho específico para cada columna */
.column-1 {
  height: 100vh;
  flex-basis: calc(10% - 20px);
  padding-top: 5%;
  /* 25% para 3 unidades, y restamos el espacio entre columnas */
  background-color: rgb(27, 27, 27);

  justify-content: center;
  align-items: center;
}

.column-1 h1 {
  font-weight: bold;
  color: #fff;
  transform: rotate(270deg);
}

.column-2 {
  height: 100vh;
  flex-basis: calc(70% - 20px);
  flex-direction: column;

}

.skills-mobile {
  display: none;
}

.column {
  display: flex;
  justify-content: center;
}

.sk-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* Two columns with equal width */
  gap: 3rem;
  /* Gap between cards */
}

@media (max-width: 801px) {
  .column-1 {
    display: none;
  }

  .column-2 {
    height: auto;
  }

  .skills-mobile {
    display: block;
    text-align: center;
  }

  .sk-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns with equal width */
    gap: 3rem;
    /* Gap between cards */
  }

}

.sk-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.sk-card div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-card div div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Optional: Set max width for cards if needed */
.sk-card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

/* Additional styles for the images */
.sk-pic {
  max-width: 50%;
  height: auto;
}

.bubble {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #474747;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.sk-card:hover .bubble {
  opacity: 1;
}

/*Footer*/
.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background: #141414;
  background-repeat: no-repeat;
  max-width: 100%;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width:801px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width:801px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social>a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social>a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

.fa-facebook:hover {
  color: #4267B2;

}

.fa-instagram:hover {
  color: #E1306C;
}

.fa-whatsapp:hover {
  color: hsl(131, 68%, 49%);
}

/*FORM*/
body {
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.container-form {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  text-align: center;
}

form {
  display: grid;
  gap: 10px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-btn {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 50%;

}

button:hover {
  background-color: #0056b3;
}

/* Add media queries for responsiveness */
@media (max-width: 801px) {
  .container-form {
    margin: 20px auto;
    padding: 10px;
  }

  form {
    gap: 5px;
  }

  input,
  textarea {
    padding: 8px;
  }

  .contact-btn {
    padding: 8px 15px;
  }
}

/* Original styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.container-form {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

h1 {
  text-align: center;
}

form {
  display: grid;
  gap: 10px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-btn {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


/* PortfolioScreen.css */
.portfolio {
  background-color: #fff;
  padding: 0 5% 0;
  /* Adjust padding for smaller screens */
  height: auto;
  margin-bottom: 2rem;
}

.project-grid {
  margin-top: 3rem;
  /* Reduce the margin for smaller screens */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.project-card {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  /* Take up full width on small screens */
  margin-bottom: 20px;
  /* Add some space between cards */
}

.project-card h3 {
  font-size: 2rem;
  margin: 0;
}

.project-card p {
  font-size: 1.2rem;
}

.project-card img {
  width: 100%;
}

/* Media query for tablets (768px width and above) */
@media screen and (min-width: 768px) {
  .portfolio {
    padding: 0 10% 0;
  }

  .project-grid {
    margin-top: 5rem;
  }

  .project-card {
    width: calc(50% - 10px);
    /* Two columns with a small gap in between */
  }
}

/* Media query for cellphones (480px width and above) */
@media screen and (max-width: 480px) {
  .project-card p {
    display: none;
    /* Hide <p> elements on smaller screens */
  }
}

/*PROMOTION SCREEN*/

.promotion-section-1 {
  height: 550px;
  background: url(../public/media/wave-1.png);
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.prom-txt-container {
  max-width: 50%;
}

.prom-txt-container h1 {
  text-align: left;
  font-size: 36px;
  font-weight: bold;
}

.prom-img-container {
  max-width: 50%;
}

.prom-img-container img {
  width: 400px;
  height: 400px;
}

/*PROMOTIONS SECTION 2*/
.prom-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prom-cards-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.prom-card-container-l {
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 10%;
  margin-top: 40px;

}

.prom-card-container-r {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
  padding-right: 10%;
  margin-top: 40px;
}

.prom-card {
  width: 750px;
  height: 160px;
  border: solid 1px;
  border-color: #000;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.prom-card img {
  height: 90%;
}

.prom-card div .pct {
  font-weight: bold;
}
.btn-ventas-c{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.btn-ventas{
  width: 280px;
  height: 40px;
  background: #3059e1;
  color: #fff;
  font-weight: bold;
}

.form-video {
  display: flex;
  justify-content: space-evenly;
  padding: 10%;
}

.form-video div {
  flex: 1;
  /* Asegura que ambos elementos ocupen un 50% del ancho */
  margin: 10px;
  /* Agrega un espacio entre los elementos */
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  max-width: 100%;
  /* Permite que el video se ajuste al ancho del contenedor */
}

@media (max-width: 768px) {
  .form-video {
    flex-direction: column;
    padding: 5%;
  }

  .form-video div {
    flex: 1;
    margin: 10px;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .form-video {
    padding: 2%;
  }

  .form-video div {
    flex: 1;
    margin: 5px;
  }
}

/* Define un punto de quiebre para dispositivos más pequeños */
@media (max-width: 768px) {
  .promotion-section-1 {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .prom-txt-container,
  .prom-img-container {
    max-width: 100%;
    padding: 10%;
  }

  .prom-txt-container h1 {
    font-size: 30px;
    text-align: left;
  }

  .prom-img-container img {
    width: 100%;
    height: auto;
  }

  /* Agrega estilos para la sección de promociones en dispositivos más pequeños */
  .prom-cards-container {
    padding: 20px;
  }

  .prom-card-container-l,
  .prom-card-container-r {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }

  .prom-card {
    width: 100%;
    padding: 10px;
  }

  .prom-card img {
    height: 50%;
  }

  .prom-card .prom-card-txt {
    height: auto;
    font-size: 12px;
  }
}

/* Define otro punto de quiebre si es necesario para pantallas aún más pequeñas */

@media (max-width: 480px) {
  /* Agrega reglas CSS específicas para pantallas muy pequeñas si es necesario */
}

/*-------------------------CountDown*/
.countdown-container {
  height: 50vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.countdown-timer div {
  margin: 0 10px;
}

.countdown-complete {
  font-size: 20px;
  font-weight: bold;
  color: #ff4500;
}

.under-construction {
  height: 90vh;
  background-color: rgb(20, 0, 68);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}



.under-construction img {
  width: 500px;
}



.terms-container {
    padding: 5%;
    font-family: Arial, sans-serif;
    color: #333;
}

.terms-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.terms-container h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
}

.terms-container p {
    font-size: 16px;
    margin-bottom: 15px;
}

.terms-container p:last-child {
    margin-bottom: 0;
}
