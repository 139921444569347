/* styles.css */

.portfolio-screen {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5%;
    background: #FFF;
  }
.p-s-1{
  width: 100%;
  height: auto;
  padding:5%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#FE5369;
  font-family: Outfit;
}
.p-s-1 h1{
  font-weight: bold;
  font-size: 60px;
}
.p-s-1 h2{
  font-size: 40px;
  font-weight: bold;
}
.p-s-1 h4{
  font-size: 32px;
}
  
  .projects-container {
    padding: 2% 8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .project-card {
    width: 48%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  .project-details {
    padding: 10px;
  }
  
  h1 {
    text-align: center;
  }
  
  h2 {
    margin-top: 10px;
  }
  
  p {
    margin-top: 10px;
  }
  
  a {
    display: inline-block;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease-in-out;
  }
  
  
  /* Media Queries */
  @media screen and (max-width: 600px) {
    .project-card {
      width: 100%;
    }
  }
  